import { Injectable } from '@angular/core';
import {Permission, Role, RoleDto, User} from "../types/user";
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './base.abstract';
import {Observable, of} from 'rxjs';
import { Pagination } from '../types/pagination.model';

export interface UserManagement {
  getUsers(): Observable<UserPaginationResponse>
  getUser(id: string): Observable<User>
  addUser(user: User): Observable<User>
  addRolesToUser(userId: string, roleIds: string[]): Observable<User>
  updateUser(id: string, user: User): Observable<User>
  getAllPermissions(): Observable<Permission[]>
  addRole(role: RoleDto): Observable<RoleDto>
  addPermissionsToRole(roleId: string, permissions: string[]): Observable<Role>
  bulkAddUpdateUser(file: File): Observable<string>
}

export interface UserPaginationResponse {
  pagination?: Pagination
  users: User[]
}

@Injectable({
    providedIn: "root"
})
export class UserManagementService extends BaseHttpService implements UserManagement {

  constructor(protected override _httpClient: HttpClient) {
    super(_httpClient, `${environment.rootAuthnUrl}`)
  }

  addUser(user: User): Observable<User> {
    return this.post<User>(`user`, user);
  }

  getUser(id: string): Observable<User> {
    return this.get<User>(`user/${id}`)
  }

  getUsers(): Observable<UserPaginationResponse> {
    return this.get<UserPaginationResponse>(`user`);
  }

  getRoles(): Observable<Role[]> {
    return this.get<Role[]>(`role`);
  }

  addRolesToUser(userId?: string, roleIds?: string[]): Observable<User> {
    if(!userId || !roleIds) {
      throw Error('AddRolesToUser: Missing userId or roleId');
    }
    return this.post<User>(`user/roles`,{userId, roles: roleIds})
  }

  updateUser(id: string, user: User): Observable<User> {
    return this.put<User>(`user/${id}`, user);
  }

  getAllPermissions(): Observable<Permission[]> {
    return this.get<Permission[]>('permission')
  }

  addRole(role: RoleDto): Observable<RoleDto> {
    return this.post<RoleDto>('role', role);
  }

  addPermissionsToRole(roleId: string, permissions: string[]): Observable<Role> {
    return this.post<Role>('role/permissions', {"permissions": permissions, "roleId": roleId})
  }

  bulkAddUpdateUser(file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.post<string>(`user/bulk-create`, formData);
  }

}
